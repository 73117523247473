export const NEW_ANNOTATION_TYPE_NAME = 'New Annotation Type';

export const ANNOTATION_ICONS = [
  'air-conditioner',
  'bullhorn',
  'bush',
  'coniferous-tree',
  'deciduous-tree',
  'doorway',
  'ear',
  'nose',
  'palm-tree',
  'smoke',
  'umbrella',
  'wind-screen',
  'window',
  'elevator',
  'information',
  'air-intake',
  'patio',
  'raindrop',
  'snowflake'
];

export const ANNOTATION_COLOURS = [
  'rgb(0, 0, 0)', // black
  'rgb(135, 138, 143)', // dark gray
  'rgb(240, 240, 238)', // light gray
  'rgb(249, 194, 10)', // yellow
  'rgb(130, 195, 65)', // green 
  'rgb(0, 114, 188)', // blue
  'rgb(96, 63, 154)', // purple
  'rgb(205, 22, 63)', // red
];